import React, {useState, useEffect} from "react"
import {Tabs, Tab} from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo";
import Loader from '../components/loading/';
import Fetch from '../helpers/fetch';
import constants from '../helpers/constants';

import EDM from '../components/settings/edm'
import Referral from '../components/settings/referral'


const SettingsPage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [settings, setSettings] = useState([])
  const [EDMSetting, setEDMSetting] = useState('')
  const [ReferralSettings, setReferralSettings] = useState('')
  const [item, setItem] = useState({optionImageUrl: ''})

  const getSettings = () => {
    const url=`${constants.BACKEND_URL}/settings`
    Fetch.get(url).then(res => {
      setEDMSetting(res.data.find(setting => setting.category === 'edm'))
      setReferralSettings(res.data.filter(setting => setting.category === 'referral'))
      const changedProperty = {}
      changedProperty['optionImageUrl'] = res.data.find(setting => setting.option === 'edm').value
      setItem({...item, ...changedProperty})
      setSettings(res.data)
    }) 
  }

  useEffect(() => {
    getSettings()
    return () => {
    }
  }, [])

  

  return (
    <Layout pageInfo={{ pageName: "settings" }}> 
      <Loader show={showLoader}></Loader>
      <SEO title="Settings Page" />
      <Tabs defaultActiveKey="edm" id="uncontrolled-tab-example">
        <Tab eventKey="edm" title="EDM">
          <EDM 
            settings={EDMSetting}
            item={item}
            setItem={setItem}
            setShowLoader={setShowLoader}
          ></EDM>
        </Tab>
        <Tab eventKey="referral" title="Referral">
          <Referral 
            settings={ReferralSettings}
            item={item}
            setItem={setItem}
            setShowLoader={setShowLoader}
          ></Referral>
        </Tab>
      </Tabs>
     
    </Layout>
  )
}

export default SettingsPage