import React, {useState, useEffect, useRef} from "react"
import {Form, Col, Row, Container, Button, Card, Tabs, Tab, Toast} from 'react-bootstrap'

import LiveUpdateTemplate from "../../helpers/emailTemplates/liveUpdate"
import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';

const Referral = ({settings, item, setItem, setShowLoader}) => {
  const [selectedFile, setSelectedFile] = useState({})
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, seterrorMessage] = useState('')

  const validated = () => {}

  const handleSubmit = () => {}
   
  return (
    <Container className="mt-3">
      <Row>
        <Col className="text-center mb-3">
          <h5>Referral Settings</h5>
          <h6 className="text-info">Settings for Referral module</h6>
        </Col>
      </Row>
      <Col>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {settings && settings.map( (item,index) => {
            return (
              <Form.Group as={Row} >
                <Form.Label column sm={4}>
                  {item.label}
                </Form.Label>
                <Col sm={8}>
                  <Form.Control 
                    required
                    data-property="name" 
                    type="text" 
                    placeholder="Please enter name" 
                    value={item['value']} 
                    disabled={true}
                    // onChange={handleItemChange} 
                    // disabled={isDisabled}
                    />
                  <Form.Control.Feedback type="invalid">Please enter Name before submission</Form.Control.Feedback>
                </Col>
              </Form.Group>
            )
          })}
        </Form>
      </Col>
      {/* <div><pre>{JSON.stringify(settings, null, 2) }</pre></div> */}
      
    </Container>
  )
}

export default Referral