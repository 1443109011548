import React from "react"

const LiveUpdateTemplate = (item) => {
  return (
    <div style={{"background-color": "#f0f0f0"}}>
      <div style={{"margin":"auto","width":"70%","padding" :"25px","background-color":"#fff","color":" #4d4d4d", "font-family" : "Helvetica,sans-serif","line-height":"1.42857","font-size":" 13px"}}>
        <img src="http://reddotbespoke.com/wp-content/uploads/2016/02/logo-white-background.png" style={{"width":"100%","max-width":"300px"}}></img>
        <p className="mt-3">Hi <strong>Customer Name</strong>, <br /><br />The following are your orders updated as of Wed Mar 03 2021 09:11:26 GMT+0800 (Singapore Standard Time)</p>
        <div class="row">
          <div class="col"><span style={{"text-align":"left"}}><strong>Order Information</strong></span></div>
          <div class="col"> <span style={{"text-align":"right"}}><strong>Your Customer Id: Cust Id</strong></span></div>
        </div>
        <table style={{"border-collapse": "collapse","text-align":"center"}}>
          <tr>
            <th style={{"width":"10%","border": "1px solid #ddd","padding": "5px"}}>Item ID</th>
            <th style={{"width":"15%","border": "1px solid #ddd","padding": "5px"}}>Fabric</th>
            <th style={{"width":"15%","border": "1px solid #ddd","padding": "5px"}}>Item Type</th>
            <th style={{"width":"10%","border": "1px solid #ddd","padding": "5px"}}>Set</th>
            <th style={{"width":"20%","border": "1px solid #ddd","padding": "5px"}}>Status</th>
            <th style={{"width":"30%","border": "1px solid #ddd","padding": "5px"}}>Item In By</th>
          </tr>
          <tr>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>20801</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>80702</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>LP</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>A</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>ProcessingSP</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>28th January to 5th February</td>
          </tr>
          <tr>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>20802</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>80702</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>LS</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>A</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>ProcessingSP</td>
            <td style={{"border": "1px solid #ddd", "padding": "5px"}}>28th January to 5th February</td>
          </tr>
        </table>
        <p><small>*SP/LP - Short/Long Pants<br />*SS/LS - Short/Long Sleeve<br />*SBV/DBV - Single/Double Breasted Vest<br />*SBJ/DBJ - Single/Double Breasted Jacket</small></p>

        <p>When all Set A item status shows "Fitting", please schedule a "Fitting" appointment.</p>
        <p>When all item status shows "Collection", please schedule a "Collection" appointment.</p>
        <p><a href="${constants.SETMORE_URL}" target="_blank">Book your appointment HERE!</a></p>
        <p>Should you require more information on your orders, feel free to connect with your consultant directly.</p>
        <p>Consultant: <strong>Red Dot Consultant</strong></p>
        <p>Office No: <strong>66124935</strong></p>
        <p>Alternatively, you may connect with us at <a href="https://www.facebook.com/Reddotbespoke/" target="_blank">Facebook</a>/ <a href="https://www.instagram.com/reddotbespoke" target="_blank">Instagram</a></p>
        <p>or email us at <strong>hello@reddotbespoke.com</strong></p>
        <p>You may refer to our FAQ for more info:</p> <a href="http://reddotbespoke.com/faq/FAQ.pdf" target="_blank">FAQ</a>
        <br />
        <p style={{"text-align":"center","color":"red", "font-size": "1rem"}}>We Thank You For Always Choosing Red Dot Bespoke</p>
        <p style={{"text-align":"center", "font-size": "1rem"}}>Here is a promotion for our beloved customer</p>
        <p style={{"text-align":"center"}}><img style={{"width": "600px"}} src={item['filePreview'] ? item['filePreview'] : item['optionImageUrl']} /></p>
        <br />
        Red Dot Bespoke Team<br />
        672 North Bridge Road #02-00 (S)188803<br />
        <br />
        Opening Hours:  <br />
        Monday - Sunday  <br />
        12pm - 8pm <br />
        66124935
        <br />
        <hr />
        <p style={{"text-align":"center"}}>Questions? Check out our <a href="http://reddotbespoke.com/faq/FAQ.pdf">Faq</a> or give us a call at +65 6612 4935.</p>

        <p style={{"text-align":"center"}}>This email was sent from a notification-only address. Please do not reply.</p>
      </div>
    </div>
  )
}

export default LiveUpdateTemplate