import React, {useState, useEffect, useRef} from "react"
import {Form, Col, Row, Container, Button, Card, Tabs, Tab, Toast} from 'react-bootstrap'

import LiveUpdateTemplate from "../../helpers/emailTemplates/liveUpdate"
import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';

const EDM = ({settings, item, setItem, setShowLoader}) => {
  const [selectedFile, setSelectedFile] = useState({})
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, seterrorMessage] = useState('')
   
  const handleUploadItemChange = (e) => {
    e.persist();
    const changedProperty = {}
    if (e.target.files && e.target.files[0]) {
      changedProperty['filePreview'] = window.URL.createObjectURL(
        new Blob([e.target.files[0]], {
          type: "image/png",
        })
      )
      setSelectedFile(e.target.files[0]);
    }
    setItem({...item, ...changedProperty});
  }

  const handleOnUploadClick = () => {
    setShowLoader(true)
    const data = new FormData() 
    data.append('file', selectedFile)
    const url=`${constants.BACKEND_URL}/settings/upload/${settings.id}`
    Fetch.post(url, data, true).then(res => {
      if(res.error) {
        setShowLoader(false);
        setShowToast(true);
        seterrorMessage(res.error);
        return;
      }
      if (res.data) {
        const imageLocation = {optionImageUrl: res.data};
        setItem({...item, ...imageLocation});
        setShowLoader(false);
      }
    })
  }
  return (
    <Container className="mt-3">
      <Row>
        <Col className="text-center mb-3">
          <h5>To upload an image which will shown inside Status Update Email. </h5>
          <h6 className="text-info">*Please upload an image with 600px in width</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              EDM Image URL
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                placeholder="EDM Image URL" 
                value={item['optionImageUrl']} 
                readOnly={true}
                />
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
              Upload Image
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                data-property="optionImageUrl" 
                type="file" 
                placeholder="Please enter name" 
                // value={item['optionImageUrl']} 
                onChange={handleUploadItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter URL before submission</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} >
            <Form.Label column sm={2}>
            </Form.Label>
            <Col sm={10}>
              <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={item['filePreview'] ? item['filePreview'] : item['optionImageUrl']} />
                <Card.Body>
                  <Card.Title>Image Preview</Card.Title>
                  <Card.Text></Card.Text>
                  <Button onClick={handleOnUploadClick} disabled={!item['filePreview']}>Upload</Button>
                </Card.Body>
              </Card>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      {/* <div><pre>{JSON.stringify(item, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(settings, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(EDMSetting, null, 2) }</pre></div> */}
      <Row>
        <Col>
          <h1>Email Preview</h1>
          {LiveUpdateTemplate(item)}
        </Col>
      </Row>
    </Container>
  )
}

export default EDM